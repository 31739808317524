import { Action, createReducer, on } from '@ngrx/store';
import { IEngageSetupResultModel } from '../../model';
import { IGatewayData } from '../../model/gateway.model';
import {
  cartItemsLoaded,
  checkTenantLoaded,
  resetCartRequested,
  wheelGatewayDataLoaded,
  engageSetupLoaded
} from '../actions/common.actions';

export interface ICommonState {
  setupData: IEngageSetupResultModel,
  gatewayData: IGatewayData
}

export const defaultState: ICommonState = {
  setupData: undefined,
  gatewayData: undefined
};

const reducer = createReducer(
  defaultState,

  on(checkTenantLoaded,
    (state, action) => ({
      ...state,
      isTenantAvailable: action.result,
    }),
  ),
  on(resetCartRequested,
    (state, action) => ({
      ...state,
      cartItems: undefined
    }),
  ),
  on(cartItemsLoaded,
    (state, action) => ({
      ...state,
      cartItems: action.result,
    }),
  ),

   on(engageSetupLoaded,
    (state, action) => ({
      ...state,
      setupData: action.result,
    }),
  ),

  on(wheelGatewayDataLoaded,
    (state, action) => ({
      ...state,
      gatewayData: action.result,
    }),
  )
)

export function commonReducer(state: ICommonState = defaultState, action: Action): ICommonState {
  return reducer(state, action);
}

export const commonStateKey = 'commonState';
