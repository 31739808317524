import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { Guid } from 'guid-typescript';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { HideSpinnerAction, ShowSpinnerAction } from '../store/actions/spinner.actions';
import { ApiResponse } from '../model/api-response.model';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  constructor(
    private store: Store<IAppState>,
    private http: HttpClient,
  ) { }

  getFeedbackTemplate(tenantId: number, locationId: number, userId: number, templateId: number, referenceNumber: string): Observable<ApiResponse> {
    const content_ = { tenantId, locationId, userId, templateId, referenceNumber };
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    // return this.http.post<ApiResponse>(`http://localhost:7031/api/services/app/EngageFeedbackTemplate/ApiDefaultFeedbackTemplate`, content_)
    return this.http.post<ApiResponse>(`/api/services/app/EngageFeedbackTemplate/ApiDefaultFeedbackTemplate`, content_)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

  saveFeedbackTemplate(tenantId: number, userId: number, feedbackData: string, feedbackTemplateId: number, locationId: number, refrenceNo: string, type: string): Observable<ApiResponse> {
    const content_ = {
      tenantId: tenantId,
      userId: userId,
      feedbackData,
      feedbackTemplateId,
      locationId: locationId,
      referenceNumber: refrenceNo,
      feedbackSource: type
    };
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
     //  return this.http.post<ApiResponse>(`http://localhost:7031/api/services/app/EngageMemberFeedback/SaveEngageMemberFeedback`, content_)
    return this.http.post<ApiResponse>(`/api/services/app/EngageMemberFeedback/SaveEngageMemberFeedback`, content_)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

}
