import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { Guid } from 'guid-typescript';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { HideSpinnerAction, ShowSpinnerAction } from '../store/actions/spinner.actions';
import { ApiResponse } from '../model/api-response.model';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WheelOrderService {
  private prodCount: number = 0;
  public prodCountCountChange: Subject<number> = new Subject();

  updateCount(count: number = 0): void {
    this.prodCount = count;
    this.prodCountCountChange.next(this.prodCount);
  }

  constructor(
    private store: Store<IAppState>,
    private http: HttpClient,
  ) { }

  getWheelOrdersByUser(userId: number): Observable<ApiResponse> {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.get<ApiResponse>(`/api/services/app/WheelOrder/GetAlllWheelOrders?customerId=${userId}`)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

  getPageToBack(pageId: number, wheelDepartermentId: number, isGenerateQRCode: boolean): Observable<ApiResponse> {
    if (isGenerateQRCode == undefined) {
      isGenerateQRCode = true;
    }
    let url_ = '/api/services/app/WheelOrder/GetPageGoToBack';
    url_ += `?currentPage=${encodeURIComponent(
      '' + pageId
    )}&wheelDepatermentId=${encodeURIComponent('' + wheelDepartermentId)}&isGenerateQRCode=${isGenerateQRCode}`;
    url_ = url_.replace(/[?&]$/, '');

    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.get<ApiResponse>(url_)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

  getCart(wheelLocationId: number, wheelDepartmentId: number, sessionId: string, IncludeSubTotal?: boolean, point?: any): Observable<ApiResponse> {

     const  body = {
        tenantId: environment.tenantId,
        wheelLocationId: wheelLocationId,
        wheelDepartmentId: wheelDepartmentId,
        sessionId: sessionId,
        IncludeSubTotal: IncludeSubTotal,
        selectedAddress: point
    };

    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(`/api/services/app/FrontCart/ApiCartItems`, body)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));

  }

}
