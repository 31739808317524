import { createAction, props } from "@ngrx/store";
import { ICartItemActionModel, IEngageSetupResultModel, IIsTenantAvailableInput } from "../../model";
import { ApiResponse } from "../../model/api-response.model";
import { IGatewayData } from "../../model/gateway.model";

export const checkTenantRequested = createAction('[Wheel Setup] check tenant available', props<{ body: IIsTenantAvailableInput }>());
export const checkTenantLoaded = createAction('[Wheel Setup] check tenant loaded', props<{ result: ApiResponse }>());

export const currentLoginInfoRequested = createAction('[Wheel Setup] Current Login Info Requested');
export const currentLoginInfoLoaded = createAction('[Wheel Setup]  Current Login Info Loaded', props<{ result: GetWheelCustomerLoginInformationsOutput }>());

export const addItemToCartRequested = createAction('[Wheel Setup] add item to cart', props<{ body: {item: any, cartModel: any} }>());
export const cartItemsLoaded = createAction('[Wheel Setup] cart items loaded', props<{ result: any }>());
export const removeItemFromCart = createAction('[Wheel Setup] remvoe item from cart', props<{ body: ICartItemActionModel }>());
export const updateItemFromCart = createAction('[Wheel Setup] update item from cart', props<{ body: ICartItemActionModel }>());
export const resetCartRequested = createAction('[Wheel Setup] reset cart requested');

export const wheelGatewayDataRequested = createAction('[Wheel GatewayData] Wheel GatewayData Requested', props<{ tenant: string }>());
export const wheelGatewayDataLoaded = createAction('[Wheel GatewayData] Wheel GatewayData Loaded', props<{ result: IGatewayData }>());

export const wheelSetupRequested = createAction('[Wheel Setup] Wheel Setup Requested', props<{ tenant: string }>());
export const engageSetupLoaded = createAction('[Wheel Setup] Wheel Setup Loaded', props<{ result: IEngageSetupResultModel }>());

export const apiResponseError = createAction('[Wheel Setup] check tenant available', props<{ error: any }>());

export class GetWheelCustomerLoginInformationsOutput {
  name!: string | undefined;
  phoneNumber!: string | undefined;
  emailAddress!: string | undefined;
  customerGuid!: string | undefined;
  userId!: number | undefined;
}
