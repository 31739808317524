import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { delay, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EngageSetupsService } from './wheel-setups.service';
import { engageSetupLoaded } from '../store/actions/common.actions';
import { DineMessageService } from './message-top-down.service';
import { AppConsts, CONSTANT } from '../common';
import { IGatewayData } from '../model/gateway.model';
import { environment } from '../../../environments/environment';
import { IEngageSetupResultModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private engageSetupsService: EngageSetupsService,
    private store: Store<any>
  ) { }


  getGatewayData(code: string) {
    return this.engageSetupsService.getGatewayInfo(code);
  }

  getEngageSetups(tenantId: number, code?: any) {
    return this.engageSetupsService.getEngageSetups(tenantId, code).pipe(tap(res => {
      this.store.dispatch(engageSetupLoaded({ result: res.result }));
      return res;
    }));
  }
}

export function StartUpFactory(
  startUpService: StartUpService,
  dineMessageService: DineMessageService,
  router: Router,
  spinner: NgxSpinnerService
) {
  return () => {
    return new Promise<boolean>(async (resolve, reject) => {
      spinner.show();
      var leng = window.location.pathname.split("/");
      var tenantName = (localStorage.getItem(CONSTANT.TENANT_CODE) || '') as string;
      if (!leng[1]) {
        reject('Tenant is not provided');
        dineMessageService.showError("Please enter the Valid URL");
        return;
      }
      const prms = await startUpService.getGatewayData(leng[1]);
      let gatewayDataForStartup = {} as any;

      prms.toPromise().then((res) => {
        let gatewayData = res as IGatewayData;
        //gatewayData.TenantUrl = 'http://localhost:7031';
        if (res && gatewayData.Code) {
          AppConsts.getWaysJSON = gatewayData;
          gatewayDataForStartup = gatewayData;
          localStorage.setItem(CONSTANT.GETWAYJSON, JSON.stringify(gatewayData));
          localStorage.setItem(CONSTANT.TENANT_NAME, gatewayData.TenantName);
          localStorage.setItem(CONSTANT.TENANT_CODE, gatewayData.Code);
          localStorage.setItem(CONSTANT.REMOTEBASEURL, gatewayData.TenantUrl);
          localStorage.setItem(CONSTANT.TENANT_ID, gatewayData.TenantId.toString());

          environment.selectedTenantName = gatewayData.TenantName
          environment.remoteServiceBaseUrl = gatewayData.TenantUrl;
          environment.tenantId = gatewayData.TenantId;
          environment.selectedTenantCode = gatewayData.Code;
          if (tenantName && leng[1].toLowerCase() !== tenantName.toLowerCase()) {
            localStorage.clear();
            window.location.reload();
          }
        } else {
          dineMessageService.showError('No Clique Setups Found for Tenant : ' + leng[1]);
          spinner.hide();
          reject('No data found');
        }
      }).then(() => {
        startUpService.getEngageSetups(gatewayDataForStartup.TenantId, gatewayDataForStartup.code)
          .pipe(tap(res => {
            spinner.hide();
            
            const selectedCode = leng[2];
            if (res.success && res.result) {
              var model = res.result as IEngageSetupResultModel;
              if (model.setups.items.length === 0) {
                dineMessageService.showError('No Clique Setups Found for Tenant : ' + gatewayDataForStartup.TenantName);
                reject('No data found');
              }

              if (selectedCode === 'page' && Number(leng[3]) > 0) {
                setTimeout(() => {
                  router.navigateByUrl(`/${gatewayDataForStartup.Code}/page/${leng[3]}`);
                }, 2000);
              }else if (model.setups.items.length > 1) {
                localStorage.setItem(CONSTANT.ENGAGESETUPJSON, JSON.stringify(res.result));

                if (selectedCode && model.setups.items.find(f => f.code.toLowerCase() === selectedCode.toLowerCase())) {
                  localStorage.setItem(CONSTANT.SELECTEDCODE, selectedCode);
                  router.navigateByUrl(`/${gatewayDataForStartup.Code}/${selectedCode}`);
                } else {
                  router.navigateByUrl(`/${gatewayDataForStartup.Code}/select-code`);
                }
              } else {
                const selCode = model.setups.items[0].code;
                environment.selectedCode = selCode;
                localStorage.setItem(CONSTANT.SELECTEDCODE, selCode);
                localStorage.setItem(CONSTANT.ENGAGESETUPJSON, JSON.stringify(res.result));

                if (location.href.indexOf(`/${gatewayDataForStartup.Code}/${selCode}`) === -1) {
                  setTimeout(() => {
                    router.navigateByUrl(`/${gatewayDataForStartup.Code}/select-code`);
                  }, 2000);
                }
              }
            } else {
              dineMessageService.showError('No Clique Setups Found for Tenant : ' + gatewayDataForStartup.TenantName);
              reject('No data found');
            }
            resolve(true);
          })).subscribe()
      })
    });
  };
}





