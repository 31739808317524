import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { IEngageItem, IEngageSetupResultModel } from '../../model';
import { ICommonState, commonStateKey } from '../reducers/common.reducers';

export const selectCommonState = createFeatureSelector<ICommonState>(commonStateKey);

export const selectSetupData = createSelector(
  selectCommonState,
  commonState => cloneDeep(commonState?.setupData) as IEngageSetupResultModel
);

export const selectAddon = (code) => createSelector(
  selectCommonState,
  commonState => commonState?.setupData?.setups && cloneDeep(commonState?.setupData.setups.items.find(f => f.code === code)) as IEngageItem
);

export const selectSplashImage = createSelector(
  selectCommonState,
  commonState => commonState.gatewayData?.WelcomeUrl
);


