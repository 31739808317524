import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IEngageSetupModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class EngageSetupsService {
  constructor(
    private http: HttpClient
  ) { }

  getEngageSetups(tenantId: number, code: number): Observable<IEngageSetupModel> {
    let url_ = `/api/services/app/EngagePortalSetup/ApiEngageSetup`;
    url_ = url_.replace(/[?&]$/, '');
    const content_ = { tenantId: tenantId, code: code };

    return this.http.post<IEngageSetupModel>(url_, content_);
  }


  getGatewayInfo(code: string): Observable<any> {
    let urlString = `${environment.gatewayUrl}${code}`;
    urlString = urlString.replace(/[?&]$/, '');

    return this.http.get<any>(urlString);
  }

}
