import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "../../model/api-response.model";

export const wheelCategoriesRequested = createAction('[Wheel Menu] Wheel Categories Requested', props<{ body: { id: number, tenantId: number } }>());
export const wheelCategoriesLoaded = createAction('[Wheel Menu] Wheel Categories Loaded', props<{ result: ApiResponse }>());

export const wheelSubCategoriesRequested = createAction('[Wheel Menu] Wheel Sub Categories Requested', props<{ body: { id: number, tenantId: number, locationId: number } }>());
export const wheelSubCategoriesLoaded = createAction('[Wheel Menu] Wheel Sub Categories Loaded', props<{catId: number, result: ApiResponse }>());

export const wheelScreenMenuItemsLoaded = createAction('[Wheel Menu] Wheel Screen Menu Loaded', props<{ pageNo: number, result: ApiResponse }>());

export const wheelScreenMenuItemsSearchLoaded = createAction('[Wheel Menu] Wheel Screen Menu Item Search Loaded', props<{ result: ApiResponse }>());

export const closeAddItemToCartPopup = createAction('[Wheel Menu] Close Add Item to Cart Popup');

export const toggleMenuViewType = createAction('[Wheel Menu] Toggle Menu View Type', props<{ isGridView: boolean }>());


export const apiResponseError = createAction('[Wheel Setup] check tenant available', props<{ error: any }>());

