import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../model/api-response.model';
import { Guid } from 'guid-typescript';
import { IAppState } from '../../store';
import { Store } from '@ngrx/store';
import { HideSpinnerAction, ShowSpinnerAction } from '../store/actions/spinner.actions';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class DynamicPagesService {
  constructor(
    private store: Store<IAppState>,
    private http: HttpClient) {
  }

  getDynamicPageById(tenantId: number, pageId:number): Observable<ApiResponse> {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(`/api/services/app/FrontWheel/getPageById`, {tenantId: tenantId, pageId: pageId})
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }
}
