import { IGatewayData } from "../../../core/model/gateway.model";
export const MY_DATE_FORMAT = 'yyyy-MM-dd';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};



export enum CONSTANT {
  SELECTED_TIME_SLOT = 'selectedTimeSlot',
  USER_TOKEN_KEY = 'userAuthenticate',
  ADDRESS_DETAIL = 'addressDetail',
  CART_ORDER = 'CART_ORDER',
  DISPlAY_ONLY = 'displayOnly',
  CURRENT_SESSION = 'currentSession',
  TENANT_NAME = 'tenantName',
  TENANT_ID = 'tenantId',
  REMOTEBASEURL = 'url',
  GETWAYJSON = 'gateWayJson',
  LOCATION = 'SELECTED_LOCATION',
  AUTH = 'Auth',
  ONLINE_CUSTOMER = 'ONLINE_CUSTOMER',
  RESERVATION_TABLE = 'RESERVATION_TABLE',
  CHECKOUT_DATA = 'CHECKOUT_DATA',
  PHONE_NUMBER = 'PHONE_NUMBER',
  TENANT_CODE = 'TENANT_CODE',
  ENGAGESETUPJSON = 'ENGAGE_SETUP_JSON',
  SELECTEDCODE = 'SELECTED_CODE'
}

export class AppConsts {

  static getWaysJSON: IGatewayData = localStorage.getItem(CONSTANT.GETWAYJSON) ? JSON.parse(localStorage.getItem(CONSTANT.GETWAYJSON) as any) : {};

  static readonly localization = {
    defaultLocalizationSourceName: 'DineConnect',
  };

  static readonly authorization = {
    encrptedAuthTokenName: 'enc_auth_token',
  };
}

export class wheelDataSetups {
  static checkOutPageId: number;
  static homePageId: number;
  static id: number;
  static logoCompanyUrl: string;
  static welcomeUrl: string;
  static advertOneUrl: string;
  static advertTwoUrl: string;
  static locations: Array<Object>;
  static homePageObject: Object;
  static currency: string;
  static title: string;
  static welcomeMessage: string;
  static checkoutMessage: string;
  static noProductImage: string;
  static isDisplayHomePage: boolean;
  static pageTitle: string;
  static wheelLocations?: Array<IWheelLocation>;
}


export interface IWheelLocation {
  id: number;
  name: string;
  enabled: boolean;
  locationId: number;
  displayMenu: boolean;
  addOns: string;
  addOnsJson: IWheelLocationAddOns;
}

export interface IWheelLocationAddOns {
  GoogleLink: string;
  HomePageBanner: string;
}


export class StepName {
  static chooseLocation = 'Choose Location';
  static chooseOrderType = 'Choose Order Type';
  static menu = 'Menu';
  static combo = 'Combo';
  static myOrder = 'My Order';
  static login = 'Customer Login';
  static signUp = 'Sign Up';
  static myProfile = 'My Profile';
  static guestInfo = 'Guest Information';
  static payment = 'Payment';
  static orderStatus = 'Order Status';
}


export class RegexPatterns {
  static emailRegex =
    /^([A-Za-z0-9_\-\.]){1,}\@([A-Za-z0-9_\-\.]){1,}\.([A-Za-z]){2,4}$/;
}


