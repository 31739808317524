import { animate, style, transition, trigger } from '@angular/animations';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';
import { wheelDataSetups } from './core/common';
import { BaseComponent } from './core/components/base.component';
import { logoutAction } from './core/store/actions/auth.actions';
import { IAppState } from './store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [ // using status here for transition
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AppComponent extends BaseComponent implements OnInit {
  preloader = true;
  animationImage = environment.animationImage;
  splashImageUrl = environment.splashImageUrl;
  toggleLeftMenu: boolean;

  constructor(
    private router: Router,
    private titleService: Title,
    store: Store<IAppState>
  ) {
    super(store);
    setTimeout(() => {
      this.preloader = false;
    }, 2000);
    this.titleService.setTitle(this.addOn?.PageTitle);
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var elem = document.getElementsByClassName('body')[0];
        if (event.url.indexOf('menu') > -1) {
          elem.classList.add('body-extend');
        } else {
          elem.classList.remove('body-extend');
        }
      }
    });
  }

  logout() {
    this.store.dispatch(logoutAction());
    this.router.navigateByUrl(`/${this.tenantCode}/${this.selectedCode}/sign-in`);
  }

  toggleMenu() {
    this.toggleLeftMenu = !this.toggleLeftMenu;
  }
}
