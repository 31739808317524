import { Action, createReducer, on } from '@ngrx/store';
import { closeAddItemToCartPopup, toggleMenuViewType, wheelCategoriesLoaded, wheelSubCategoriesLoaded, wheelSubCategoriesRequested } from '../actions/menu.actions';

export interface IMenuState {
  subCategories: {
    [category: string]: {
      loading: boolean;
      completed: boolean;
      error: boolean;
      request: string,
      data: string[];
    }
  },
  isGridView: boolean;
}

export const defaultState: IMenuState = {
  subCategories: {},
  isGridView: false
};

const reducer = createReducer(
  defaultState,

  on(wheelCategoriesLoaded,
    (state, action) => ({
      ...state,
      categories: action.result.result.categories,
    }),
  ),

  on(wheelSubCategoriesRequested,
    (state, action) => ({
      ...state,
      subCategories: {
        ...state.subCategories,
        [action.body.id]: {
          ...state.subCategories[action.body.id],
          loading: true,
        }
      }
    }),
  ),
    on(wheelSubCategoriesLoaded,
    (state, action) => ({
      ...state,
      subCategories: {
        ...state.subCategories,
        [action.catId]: {
          loading: false,
          error: false,
          completed: true,
          data: action.result.result.subCategories,
        }
      }
    }),
  ),

  on(closeAddItemToCartPopup,
    (state) => ({
      ...state,
      popupItem: undefined,
    }),
  ),
  on(toggleMenuViewType,
    (state, action) => ({
      ...state,
      isGridView: action.isGridView,
    }),
  )
)

export function menuReducer(state: IMenuState = defaultState, action: Action): IMenuState {
  return reducer(state, action);
}

export const menuStateKey = 'menuState';
