import { Component } from '@angular/core';
import { UserService, ScriptLoaderService } from '../service';
import { Unsubscriber } from './unsubscriber.class';
import { GOOGLE_MAP_KEY, SELECTED_DEPARTMENT, SELECTED_ADDRESS } from '../common/constant/shared.const';
import { CONSTANT, IWheelLocation, wheelDataSetups } from '../common/constant/constant';
import { ServiceLocator } from '../service/base-service-locator';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { NgxSpinnerService } from 'ngx-spinner';
import { IGatewayData } from '../model/gateway.model';
import { selectAddon } from '../store/selectors/common.selectors';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IEngageSettings, IMemberDetailModel } from '../model';

import { selectIsLoggedIn, selectMemberDetail } from '../store/selectors/auth.selector';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-root',
  template: ``,
  styles: [``],
})
export class BaseComponent extends Unsubscriber {
  logoUrl: string = '';
  isLoggedIn = false;
  loggedInUser: IMemberDetailModel;
  googleMapSrc =
    'https://maps.googleapis.com/maps/api/js?key=' +
    GOOGLE_MAP_KEY +
    '&libraries=places';

  userService: UserService;
  scriptLoaderService: ScriptLoaderService;
  spinner: NgxSpinnerService;
  store: Store<IAppState>;
  tableName: string = '';
  addOn: IEngageSettings;
  baseColor: string = '#bf1b2f';
  alternateColor: string = 'darkgrey';
  backgroundColor: string;
  facebookLink: string;
  instagramLink: string;
  twitterLink: string;

  constructor(
    store: Store<IAppState>
  ) {
    super();

    this.userService = ServiceLocator.injector.get(UserService);
    this.scriptLoaderService = ServiceLocator.injector.get(ScriptLoaderService);
    this.spinner = ServiceLocator.injector.get(NgxSpinnerService);
    this.store = store;

    this.store.select(selectAddon(environment.selectedCode))
      .pipe(filter(f => Boolean(f)),
        tap(result => {
          var r = JSON.parse(result.settings) as IEngageSettings;
          this.addOn = r;
          this.backgroundColor = r.BackgroundColor;
          this.baseColor = r.ButtonBaseColor;
          this.alternateColor = r.ButtonAlternateColor;
          this.logoUrl = r.Logo;

          if (this.backgroundColor) {
            document.body.style.backgroundColor = this.backgroundColor;
          } else {
            document.body.removeAttribute('style');
          }
        })).subscribe();

    this.store.select(selectIsLoggedIn).pipe(tap(res => {
      this.isLoggedIn = res;
    }), takeUntil(this.unsubscribe))
      .subscribe();

    this.store.select(selectMemberDetail).pipe(tap(res => {
      this.loggedInUser = res;
    }), takeUntil(this.unsubscribe))
      .subscribe();
  }

  loadGoogleScript(): Promise<any> {
    return this.scriptLoaderService.loadScript(this.googleMapSrc);
  }

  get userName() {
    return this.loggedInUser?.name || 'Guest User';
  }

  tenantId() {
    let jsonData = localStorage.getItem(CONSTANT.GETWAYJSON);
    const parse = JSON.parse(jsonData) as IGatewayData;
    return parse.TenantId;
  }

  get tenantName() {
    let jsonData = localStorage.getItem(CONSTANT.GETWAYJSON);
    const parse = JSON.parse(jsonData) as IGatewayData;
    return parse.TenantName;
  }

  get tenantCode() {
    let jsonData = localStorage.getItem(CONSTANT.GETWAYJSON);
    const parse = JSON.parse(jsonData) as IGatewayData;
    return parse.Code;
  }

  get selectedCode() {
    return environment.selectedCode;
  }


}
