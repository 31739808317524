import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {environment} from '../environments/environment';
import { RouteResolverService } from './core/common/resolvers/route.resolver';

export const routes: Routes = [
  {
    path: `${environment.selectedTenantCode}/${environment.selectedCode}`,
    loadChildren: () => import('./modules/landing/landing.module').then((x) => x.LandingModule),
    resolve: { data: RouteResolverService }
  },
  {
    path: `${environment.selectedTenantCode}/${environment.selectedCode}/:locId/:refId/:type/:templateId/feedback`,
    loadChildren: () => import('./modules/feedback/feedback.module').then((x) => x.FeedbackModule),
    resolve: { data: RouteResolverService }
  },
  {
    path: `${environment.selectedTenantCode}/select-code`,
    loadChildren: () => import('./modules/select-code/select-code.module').then(m => m.SelectCodeModule),
  },
  {
    path: `${environment.selectedTenantCode}/page/:id`,
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule),
    resolve: { data: RouteResolverService },
    pathMatch:'full'
  },
  {
    path: '',
    redirectTo: `${environment.selectedTenantCode}`,
    resolve: { data: RouteResolverService },
    pathMatch:'full'
  },
  { path: '**', redirectTo: `${environment.selectedTenantCode}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
