import { createSelector } from "@ngrx/store";
import { IMemberDetailModel } from "../../model";
import { IAuthState } from "../reducers/auth.reducer";

export const selectAuthState = state => state.authStateKey as IAuthState;

export const selectIsLoggedIn = createSelector(
  selectAuthState,
  auth => auth?.isLoggedIn
);

export const selectMemberDetail = createSelector(
  selectAuthState,
  auth => auth?.memberDetail as IMemberDetailModel
);
