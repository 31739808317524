import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { StartUpService } from '../../service/start-up.service';
import { wheelDataSetups } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})

export class RouteResolverService implements Resolve<any> {
  constructor(private wheelSetupsService: StartUpService) {
    //this.router.events
    //  .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    //  .subscribe((events: RoutesRecognized[]) => {
    //    console.log('previous url', events[0].urlAfterRedirects);
    //    console.log('current url', events[1].urlAfterRedirects);
    //  });
  }

  resolve(): Observable<any> {
    var id = environment.tenantId;
    if (wheelDataSetups?.wheelLocations?.length > 0) {
      return of(wheelDataSetups);
    } else {
      return this.wheelSetupsService.getEngageSetups(id, environment.selectedCode);
    }
  }
}
