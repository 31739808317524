import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { HideSpinnerAction, ShowSpinnerAction } from '../store/actions/spinner.actions';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse } from '../model/api-response.model';
import { ICampaignDetailRequestModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  constructor(
    private client: HttpClient,
    private store: Store<IAppState>
  ) { }

  getCampaignDetails(model: ICampaignDetailRequestModel): Observable<ApiResponse> {
    let url = '/api/services/app/EngagePortalTrans/ApiPointCampaignDetails'.replace(/[?&]$/, '');

    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.client.post<ApiResponse>(url, model)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

}
