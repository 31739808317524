<ngx-spinner bdColor="{{alternateColor}}" template="<img src='{{animationImage}}' />">
</ngx-spinner>


<div class="container px-0 engage-container position-relative">
<ng-container *ngIf="preloader;else showRoute">
  <app-preloader @fade></app-preloader>
</ng-container>
  <ng-template #showRoute>
    <router-outlet></router-outlet>
  </ng-template>

  <div class="edine-by-levelfive" *ngIf="!preloader">
    <span>Engage by&nbsp;<a href="https://lfsolutions.net">LevelFive Solutions </a> </span>
  </div>
</div>

<section class="fixed-area side-bar-area">
  <header>
    <nav class="navbar navbar-2 menu-bar" [style.background]="baseColor">
      <div class="navbar-left mr-auto" (click)="toggleMenu()">
        <div class="hamburger-menu" [class.active]="toggleLeftMenu">
          <div class="bar-top"></div>
          <div class="bar-middle"></div>
          <div class="bar-bottom"></div>
        </div>
      </div>
    </nav>
    <div class="side__menu" [style.display]="toggleLeftMenu ? 'block': 'none'">
      <ul class="__go-in">
        <li *ngIf="!isLoggedIn">
          <a class="cursor-pointer" (click)="toggleMenu()" [routerLink]="['./' + tenantCode + '/' + selectedCode + '/sign-in']">Sign In</a>
        </li>
        <li *ngIf="!isLoggedIn">
          <a class="cursor-pointer" (click)="toggleMenu()" [routerLink]="['./' + tenantCode + '/' + selectedCode + '/sign-up']">Sign Up</a>
        </li>
      </ul>
      <ul class="__explore">
        <li><a href="javascript:" (click)="toggleMenu()" [routerLink]="['./' + tenantCode + '/' + selectedCode]">Home</a></li>
        <li><a href="javascript:" (click)="toggleMenu()" [routerLink]="['./' + tenantCode + '/' + selectedCode + '/profile']">Profile</a></li>
        <li><a href="javascript:" (click)="toggleMenu()" [routerLink]="['./' + tenantCode + '/' + selectedCode + '/history']">History</a></li>
        
        <li *ngIf="isLoggedIn">
          <a href="javascript:" (click)="logout();toggleMenu()">Log Out</a>
        </li>
      </ul>
    </div>
  </header>
</section>
