import { DineMessageService } from '../common';
import { UserService } from './user.service';
import { AlertService } from './alert.service';
import { PagesService } from './pages.service';
import { LocalCookieService } from './local-cookie.service';
import { ScriptLoaderService } from './script-loader.service';
import { WheelOrderService } from './wheel-order.service';
import { EngageSetupsService } from './wheel-setups.service';
import { DataLoaderService } from './loaders/data-loader.service';
import { ToastService } from './toast.service';
import { TenantService } from './tenant.service';
import { DynamicPagesService } from './dynamic-pages.service';
import { FeedbackService } from './feedback.service';

export * from './user.service';
export * from './alert.service';
export * from './pages.service';
export * from './message-top-down.service';
export * from './script-loader.service';
export * from './wheel-setups.service';
export * from './local-cookie.service';
export * from './loaders/data-loader.service';
export * from './toast.service';
export * from './tenant.service';
export * from './dynamic-pages.service';
export * from './feedback.service';

export const coreServices = [
  UserService,
  AlertService,
  PagesService,
  DineMessageService,
  ScriptLoaderService,
  WheelOrderService,
  EngageSetupsService,
  LocalCookieService,
  DataLoaderService,
  ToastService,
  TenantService,
  DynamicPagesService,
  FeedbackService
];
