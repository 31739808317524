import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  ErrorHandler
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CUSTOM_DATE_FORMATS, DineMessageService } from './core/common';
import { ScriptLoaderService } from './core/service';
import { AppInterceptor } from './core/common/authenticate';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { rootComponents } from './modules';
import { ServiceLocator } from './core/service/base-service-locator';
import { ClipboardModule } from 'ngx-clipboard';
import { StartUpFactory, StartUpService } from './core/service/start-up.service';
import { Router } from '@angular/router';
import { SurveyModule } from "survey-angular-ui";

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, `${window.location.origin}/assets/i18n/`, '.json');
};

@NgModule({
  declarations: [AppComponent, ...rootComponents],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    CookieModule.forRoot(),
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    ClipboardModule,
    SurveyModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers: [],
      runtimeChecks: {},
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      })
      : []
  ],
  providers: [
    CookieService,
    StartUpService,
    ScriptLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: StartUpFactory,
      deps: [StartUpService, DineMessageService, Router, NgxSpinnerService],
      multi: true,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule {
  constructor(private injector: Injector){    // Create global Service Injector.
    ServiceLocator.injector = this.injector;
}

}
