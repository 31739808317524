import { Action, createReducer, on } from "@ngrx/store";
import { ILogingResponseModel, IMemberDetailModel } from "../../model";
import { LoggedInAction, logoutAction, UserLoaded } from "../actions/auth.actions";

export interface IAuthState {
  isLoggedIn:boolean,
  user: ILogingResponseModel,
  memberDetail: IMemberDetailModel
}

export const defaultState: IAuthState = {
  isLoggedIn:false,
  user: undefined,
  memberDetail: undefined
};

const reducer = createReducer(
  defaultState,

  on(LoggedInAction,
    (state, action) => ({
      ...state,
      authUser: action.user,
      isLoggedIn: action.user?.userId > 0
    }),
  ),
  on(logoutAction,
    (state, action) => ({
      ...state,
      authUser: undefined,
      memberDetail: undefined,
      isLoggedIn: false
    }),
  ),
  on(UserLoaded,
    (state, action) => ({
      ...state,
      memberDetail: action.user,
    }),
  ),
)

export function authReducer(state: IAuthState = defaultState, action: Action): IAuthState {
  return reducer(state, action);
}

export const authStateKey = 'authStateKey';
