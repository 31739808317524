import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  tenantsName = environment.selectedTenantName;
  constructor() {}

  getTenantByHostname(hostname: string): string {
    return this.getTenantByHost(hostname.split('.')[0]);
  }

  getTenantByHost(host: string): string {
    return this.getTenantByString(host);
  }

  getTenantByString(s: string): string {
    return this.tenantsName;
  }

  getTenant(): string {
    return this.getTenantByHostname(location.hostname);
  }

  addTenantToHeader(headers: HttpHeaders): HttpHeaders {
    return headers.append('tenancyName', this.getTenant());
  }

  get defaultImageUrl() {
    return `https://dineapi.xyz/TenantCustomization/GetMenuItemDefault?tenantId=${environment.tenantId}` ;
  }

}
