import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WheelOrderService } from '../../service/wheel-order.service';

@Injectable()
export class CommonEffect {

  //@Effect()
  //addItemToCart$ = this.actions$.pipe(
  //  ofType(addItemToCartRequested),
  //  map(action => action.body),
  //  mergeMap((payload: { item: any, cartModel: any }) => {
  //    return this.orderService.addOrderItemToCart(payload).pipe(
  //      map(
  //        (resp: any) => {
  //          //if (resp.success) {
  //          //  return cartItemsRequested(
  //          //    {
  //          //      body: payload.cartModel
  //          //    })
  //          //}
  //          return apiResponseError(resp.result.error.message);
  //        }
  //      ),
  //      catchError(
  //        error => of(apiResponseError(error))),
  //    );
  //  }));

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private orderService: WheelOrderService) {
  }


}
