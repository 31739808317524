import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { wheelCategoriesRequested, wheelSubCategoriesRequested } from '../../store/actions/menu.actions';
import { selectSubCategories } from '../../store/selectors/menu.selectors';
import { DataLoader } from './data-loader';


@Injectable({
  providedIn: 'root'
})
export class DataLoaderService extends DataLoader {
  constructor(protected store: Store<any>) {
    super(store);
  }

}
