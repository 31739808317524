import { CONSTANT } from '../../core/common';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { ILogingResponseModel, ILoginRequestModel, ISignupRequestModel, IUser } from '../model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { IAppState } from '../../store';
import { Store } from '@ngrx/store';
import { HideSpinnerAction, ShowSpinnerAction } from '../store/actions/spinner.actions';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse } from '../model/api-response.model';
import { IGatewayData } from '../model/gateway.model';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: Observable<IUser>;

  constructor(
    private _cookieService: CookieService,
    private http: HttpClient,
    private store: Store<IAppState>
  ) {

  }

  login(model: ILoginRequestModel) {
    let user = {} as ILogingResponseModel;
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post(`${environment.remoteServiceBaseUrl}/api/Account/Authenticate`, model)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

  signup(model: ISignupRequestModel) {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post(`${environment.remoteServiceBaseUrl}/api/services/app/EngagePortalSetup/ApiSignUp`, model)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

  getMemberDetail(tenantId: number, userId: number): Observable<ApiResponse> {
    let url = '/api/services/app/EngagePortalTrans/ApiMemberDetail'.replace(/[?&]$/, '');

    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(url, { tenantId, userId })
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

  logout() {
    return of({} as ApiResponse);
  }

  public get userToken() {
    if (localStorage.getItem(CONSTANT.AUTH)) {
      const user = JSON.parse(localStorage.getItem(CONSTANT.AUTH)) as ILogingResponseModel;
      return user.result;
    }

    return '';
  }

  get userId() {
    if (localStorage.getItem(CONSTANT.AUTH)) {
      const user = JSON.parse(localStorage.getItem(CONSTANT.AUTH)) as ILogingResponseModel;
      return user.userId;
    }
    return 0;
  }

  get tenantCode() {
    let jsonData = localStorage.getItem(CONSTANT.GETWAYJSON);
    const parse = JSON.parse(jsonData) as IGatewayData;
    return parse.Code;
  }

}
