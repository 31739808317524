import { AppConsts, CONSTANT } from "../app/core/common";

export const environment = {
  production: false,
  appBaseUrl: "http://localhost:4300",
  remoteServiceBaseUrl: localStorage.getItem(CONSTANT.REMOTEBASEURL),
  selectedTenantName: localStorage.getItem(CONSTANT.TENANT_NAME),
  selectedTenantCode: localStorage.getItem(CONSTANT.TENANT_CODE) ? localStorage.getItem(CONSTANT.TENANT_CODE) : getTenantCode(),
  selectedCode: localStorage.getItem(CONSTANT.SELECTEDCODE) ? localStorage.getItem(CONSTANT.SELECTEDCODE) : getSelectedCode(),
  tenantId: localStorage.getItem(CONSTANT.TENANT_ID) ? parseInt(localStorage.getItem(CONSTANT.TENANT_ID) as any) : 1,
  gatewayUrl: 'https://dgateway.xyz/api/gateway/read/2/',
  splashImageUrl: '',
  animationImage: ''
};

function getTenantCode(): any {

  var leng = window.location.pathname.split("/");
  if (AppConsts.getWaysJSON?.Code) {
    var tenantNameslist = AppConsts.getWaysJSON.Code;
    if (leng.some(r => tenantNameslist.includes(r))) {
      var tenantName = leng.filter(x => tenantNameslist.includes(x));
      let tenantObject = AppConsts.getWaysJSON.Code == tenantName[0];
      if (tenantObject) {
        return tenantName[0];
      }
    } else {
      return leng[1];
    }
  } else if (leng[1]) {
    localStorage.setItem(CONSTANT.TENANT_CODE, leng[1]);
    return leng[1];
  }
}

function getSelectedCode(): any {
  var leng = window.location.pathname.split("/");
  const selectedCode = leng[2];
  var str = localStorage.getItem(CONSTANT.ENGAGESETUPJSON);
  var clickSetup = JSON.parse(str);

  if (clickSetup?.setups?.items?.length === 1) {
    const code = clickSetup?.setups?.items[0]?.code;
    if (code && code.toString() === selectedCode)
      return code;
  } else if (selectedCode && selectedCode.indexOf('select-code') === -1) {
    return selectedCode;
  }
}
